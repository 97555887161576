// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[5].use[1]!../../../node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[5].use[2]!../../App.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.slider-container {
  width: calc(80% - 20px);
  margin: 200px auto 0px;
  overflow: hidden;
}

.slider {
  display: flex;
  transition: transform 0.5s ease-in-out;
}

.slide {
  min-width: 100%;
  border-radius: 8px;
}

.slide img {
  width: 100%;
  height: auto;
  display: block;
  border-radius: inherit;
}

.dots {
  text-align: center;
  width: 100%;
  margin-top: 25px;
}

.dot {
  height: 10px;
  width: 10px;
  margin: 0 5px;
  background-color: var(--color-blue-cream);
  border-radius: 50%;
  display: inline-block;
  transition: background-color 0.6s ease;
  cursor: pointer;
}

.dot.active {
  background-color: var(--color-blue-dark);
}

@media only screen and (max-width: 600px) {
  .slider-container {
    width: 90%;
    margin: 25px auto 0px;
    overflow: hidden;
  }
}

@media only screen and (min-width: 601px) and (max-width: 1024px) {
  .slider-container {
    width: 90%;
    margin: 175px auto 0px;
    overflow: hidden;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/banner-list/BannerList.css"],"names":[],"mappings":"AAEA;EACE,uBAAuB;EACvB,sBAAsB;EACtB,gBAAgB;AAClB;;AAEA;EACE,aAAa;EACb,sCAAsC;AACxC;;AAEA;EACE,eAAe;EACf,kBAAkB;AACpB;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,cAAc;EACd,sBAAsB;AACxB;;AAEA;EACE,kBAAkB;EAClB,WAAW;EACX,gBAAgB;AAClB;;AAEA;EACE,YAAY;EACZ,WAAW;EACX,aAAa;EACb,yCAAyC;EACzC,kBAAkB;EAClB,qBAAqB;EACrB,sCAAsC;EACtC,eAAe;AACjB;;AAEA;EACE,wCAAwC;AAC1C;;AAEA;EACE;IACE,UAAU;IACV,qBAAqB;IACrB,gBAAgB;EAClB;AACF;;AAEA;EACE;IACE,UAAU;IACV,sBAAsB;IACtB,gBAAgB;EAClB;AACF","sourcesContent":["@import url(\"../../App.css\");\n\n.slider-container {\n  width: calc(80% - 20px);\n  margin: 200px auto 0px;\n  overflow: hidden;\n}\n\n.slider {\n  display: flex;\n  transition: transform 0.5s ease-in-out;\n}\n\n.slide {\n  min-width: 100%;\n  border-radius: 8px;\n}\n\n.slide img {\n  width: 100%;\n  height: auto;\n  display: block;\n  border-radius: inherit;\n}\n\n.dots {\n  text-align: center;\n  width: 100%;\n  margin-top: 25px;\n}\n\n.dot {\n  height: 10px;\n  width: 10px;\n  margin: 0 5px;\n  background-color: var(--color-blue-cream);\n  border-radius: 50%;\n  display: inline-block;\n  transition: background-color 0.6s ease;\n  cursor: pointer;\n}\n\n.dot.active {\n  background-color: var(--color-blue-dark);\n}\n\n@media only screen and (max-width: 600px) {\n  .slider-container {\n    width: 90%;\n    margin: 25px auto 0px;\n    overflow: hidden;\n  }\n}\n\n@media only screen and (min-width: 601px) and (max-width: 1024px) {\n  .slider-container {\n    width: 90%;\n    margin: 175px auto 0px;\n    overflow: hidden;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
