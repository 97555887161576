// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Home.css */
.loading-enter,
.error-enter,
.products-enter {
    opacity: 0;
}

.loading-enter-active,
.error-enter-active,
.products-enter-active {
    opacity: 1;
    transition: opacity 0.5s;
}

.loading-exit,
.error-exit,
.products-exit {
    opacity: 1;
}


.loading-exit-active,
.error-exit-active,
.products-exit-active {
    opacity: 0;
    transition: opacity 0.5s;
}`, "",{"version":3,"sources":["webpack://./src/views/home/Home.css"],"names":[],"mappings":"AAAA,aAAa;AACb;;;IAGI,UAAU;AACd;;AAEA;;;IAGI,UAAU;IACV,wBAAwB;AAC5B;;AAEA;;;IAGI,UAAU;AACd;;;AAGA;;;IAGI,UAAU;IACV,wBAAwB;AAC5B","sourcesContent":["/* Home.css */\n.loading-enter,\n.error-enter,\n.products-enter {\n    opacity: 0;\n}\n\n.loading-enter-active,\n.error-enter-active,\n.products-enter-active {\n    opacity: 1;\n    transition: opacity 0.5s;\n}\n\n.loading-exit,\n.error-exit,\n.products-exit {\n    opacity: 1;\n}\n\n\n.loading-exit-active,\n.error-exit-active,\n.products-exit-active {\n    opacity: 0;\n    transition: opacity 0.5s;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
